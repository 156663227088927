import {useEffect, useState} from "react";

import {Footer} from "../components/modules/Footer/Footer";
import Head from "next/head";
import {Navbar} from "../components/modules/Navbar/Navbar";
import type {NextPage} from "next";
import {isMobile} from "react-device-detect";
import Script from "next/script";

const Page: NextPage = () => {
  const [isOpenModal, setModalOpen] = useState(false);
  const showModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  useEffect(() => {
    if (isMobile) {
      showModal();
    }
  }, []);
  return (
    <div className="">
      <Head>
        {/*<link rel="canonical" href="https://www.photojoiner.com" />*/}
        <Script
          src="https://sak.userreport.com/photopix/launcher.js"
          async
          id="userreport-launcher-script"
        ></Script>
      </Head>
      <Navbar backgroundColor="#2867E1" textColor="white" whiteLogo={true} />

      <div className=" flex h-96 items-center justify-center">
        <div
          style={{
            display: "block",
            overflow: "hidden",
            fontWeight: "500",
            fontSize: "3rem",
            paddingRight: "10px",
            marginBottom: "10px",
            borderBottom: "1px solid rgba(0, 0, 0,.3)",
          }}
        >
          404
        </div>
        <div
          style={{
            padding: "20px",
            textAlign: "center",
            display: "inline",
            overflow: "hidden",
          }}
        >
          Not Found
        </div>
      </div>
      <Footer
        titleColor="#F8F8F8"
        backgroundColor="#0E3683"
        textColor="#FFFFFF"
        whiteLogo={true}
      />
    </div>
  );
};

export default Page;
